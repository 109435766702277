import { Branch } from "./partner_model";
export type SubUserType = {
  email: string;
  role?: UserRole;
  quickPass?: string;
  branch_id?: string;
  permissions?: AccessType[];
  branch?: Branch;
  id: number;
  subuser_id: number;
  name: string;
  lastname: string;
  status: string;
  privileges: number;
};

export enum UserRole {
  admin = "admin",
  cook = "cook",
  waiter = "waiter",
  delivery = "delivery",
  cashier = "cashier",
  editor = "editor",
  manager = "manager",
  books = "books",
}

export enum AccessType {
  email_notify = "email_notify",
  change_prices = "change_prices",
  cancel_orders = "cancel_orders",
  view_branches = "view_branches",
  open_drawer = "open_drawer",
  cash_outs = "cash_outs",
  view_past_orders = "view_past_orders",
}
