import React from "react";
import { Link } from "react-router-dom";
import foo_logo from "../../assets/foo_mascot_chef_hat@2x.svg";

const General: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "");
  return (
    <div className="row justify-content-center mt-3">
      <div className="col">
        <div className="card sticky-top">
          <img
            src="https://via.placeholder.com/350x100/3e72af/3e72af"
            alt="blue background"
          />
          <div className="card-body text-center">
            <button type="button" className="btn btn-transparent user_avatar">
              <img
                src={
                  user && user.partner.logo !== null
                    ? `https://cdn.foopos.mx${user.partner.logo
                        .replace("/img", "")
                        .slice(0)}`
                    : foo_logo
                }
                className=""
                style={{ maxWidth: "60%" }}
                alt="companylogo"
              />
            </button>
            <p>
              {user.partner.company_name}
              <br />
              {user.email} <br />
              {user.name} <br />
              <br />
              {`v.${process.env.REACT_APP_CURRENT_GIT_SHA}`}
            </p>
            <button
              type="button"
              className="btn btn-link text-danger btn-sm mt-4"
            >
              Cambiar Password
            </button>
          </div>
        </div>
      </div>
      <Link className="btn btn-block btn-danger mt-4" to="/logout">
        Cerrar sesión
      </Link>
    </div>
  );
};

export default General;
