import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import hide_footer_url from "../../data/hide_footer.json";

const Item = ({ icon, link, badge }) => (
  <Link to={link} className="btn btn-link position-relative">
    <i className={`fa fa-fw ${icon}`} aria-hidden="true"></i>
    {badge ? (
      <span className="badge badge-pill badge-danger">{badge}</span>
    ) : null}
  </Link>
);
const NavMob = () => {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);
  useEffect(() => {
    hide_footer_url.forEach((url) => {
      if (location.pathname.search(url) >= 0) {
        setShowFooter(false);
      } else if (!showFooter) {
        setShowFooter(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!showFooter) {
    return null;
  }
  return (
    <footer className="footer partner_footer footer_mob d-lg-none">
      <div className="app d-flex d-lg-none justify-content-around animate_all no_focus">
        <Item link="/" icon="fa-inbox" />
        <Item link="/invite" icon="fa-star" />
        <Link
          to="/point_of_sale"
          className="btn btn-lg btn-circle btn-raised btn-danger btn_dashboard dash_change "
        >
          <i className="fas fa-plus fa-fw " aria-hidden="true"></i>
        </Link>

        <a
          href="/point_of_sale"
          className="btn btn btn-circle  btn-link "
          role="button"
        >
          <i className="far fa-bell fa-fw " aria-hidden="true"></i>
        </a>
        <Item
          link="/notifications"
          icon="far fa-bell btn-link position-relative"
          badge="2"
        />

        <Item link="/dash_navigation" icon=" fas fa-bars" />
      </div>
    </footer>
  );
};

export default NavMob;
