import { User } from "../models/user_model";
const user: User = JSON.parse(localStorage.getItem("user") || "{}");

/// privs list
//     admin: 1,
//     view_orders: 2,
//     make_orders: 4,
//     charge_orders: 8,
//     users: 16,
//     sales: 32,
//     invoices: 64,
//     edit_info: 128,
//     products: 256,
//     cancel_orders: 512,
//     view_branches: 1024,
//     change_prices: 2048,
//     open_drawer: 4096,
//     email_notify: 8192,
//     cash_outs: 16384,
//     view_past_orders: 32768,
//     'new: 65536,
//     'new: 131072,

export function has_access(privilige?: number): boolean {
  if (user.privileges === 1 || !privilige) {
    return true;
  }
  if (!(user.privileges & privilige)) {
    return false;
  }
  return true;
}
