import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { DateTime } from "luxon";

import { lang } from "../../../middleware/lang";
import { LoaderDots } from "../../../middleware/loaders";
import {
  SalesDBType,
  SalesDelivery,
  SalesPayment,
  SalesType,
} from "../../../models/sales_model";
import { GETSALES } from "./queries";
import SalesResume from "./resume";
import Charts from "./charts";
import { DeliveryType } from "../../../models/pos_model";
import { activeBranchVar } from "../../../middleware/cache";

import SalesNav from "./salesNav";
import SalesOrders from "./orders";
import SalesProducts from "./products";

export interface SalesFetchedData {
  getSales: SalesDBType;
}
type SalesDates = {
  start: string;
  startDate: Date;
  end: string;
  endDate: Date;
};
const todayDateTime = DateTime.local();
const SalesPanel: React.FC = () => {
  const { section } = useParams<{ section: string }>();
  const activeBranch = useReactiveVar(activeBranchVar);
  const [sales, setSales] = useState<SalesType>({
    delivery: [],
    payment: [],
    timeline: [],
  });

  const [dates, setDates] = useState<SalesDates>({
    start: todayDateTime.toISODate(),
    end: todayDateTime.toISODate(),
    startDate: new Date(),
    endDate: new Date(),
  });
  const [getSales, { loading, data: sales_data }] =
    useLazyQuery<SalesFetchedData>(GETSALES, {
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    getSales({
      variables: {
        branch_id: +activeBranch,
        startDate: dates.start,
        endDate: dates.end,
      },
    });
    //eslint-disable-next-line
  }, [dates, activeBranch]);

  useEffect(() => {
    const set_sales: SalesType = {
      delivery: [],
      payment: [],
      timeline: [],
    };
    if (sales_data) {
      const timeline: any = {};
      const delivery: SalesDelivery = {
        type: DeliveryType.delivery,
        type_txt: lang("A domicilio", "Delivery"),
        amount: 0,
        total: 0,
      };
      const payment: SalesPayment = {
        type: "cash",
        type_txt: lang("Efectivo", "Cash"),
        amount: 0,
        total: 0,
      };
      const pickup: SalesDelivery = {
        type: DeliveryType.pickup,
        type_txt: lang("Para Recoger", "Pickup"),
        amount: 0,
        total: 0,
      };
      const other_apps: SalesDelivery = {
        type: DeliveryType.other_apps,
        type_txt: lang("Otras Plataformas", "Other Apps"),
        amount: 0,
        total: 0,
      };
      const for_here: SalesDelivery = {
        type: DeliveryType.for_here,
        type_txt: lang("En Comedor", "For Here"),
        amount: 0,
        total: 0,
      };

      const cash_payment: SalesPayment = {
        type: "cash",
        type_txt: lang("Efectivo", "Cash"),
        amount: 0,
        total: 0,
      };
      const online_payment: SalesPayment = {
        type: "online",
        type_txt: "Online",
        amount: 0,
        total: 0,
      };
      const card_pos_payment: SalesPayment = {
        type: "card_pos",
        type_txt: "Foo Pay",
        amount: 0,
        total: 0,
      };
      const terminal_payment: SalesPayment = {
        type: "terminal",
        type_txt: "Terminal",
        amount: 0,
        total: 0,
      };
      const other_apps_payment: SalesPayment = {
        type: "other_apps",
        type_txt: lang("Otras Plataformas", "Other Apps"),
        amount: 0,
        total: 0,
      };
      sales_data.getSales.orders.forEach((order) => {
        switch (order.delivery_type) {
          case "delivery":
            delivery.amount++;
            delivery.total += order.total;
            break;
          case "pickup":
            pickup.amount++;
            pickup.total += order.total;
            break;
          case "other_apps":
            other_apps.amount++;
            other_apps.total += order.total;
            break;
          case "for_here":
            for_here.amount++;
            for_here.total += order.total;
            break;
        }

        switch (order.payment_type) {
          case "cash":
            cash_payment.amount++;
            cash_payment.total += order.total;
            break;
          case "online":
            online_payment.amount++;
            online_payment.total += order.total;
            break;
          case "card_pos":
            card_pos_payment.amount++;
            card_pos_payment.total += order.total;
            break;
          case "terminal":
            terminal_payment.amount++;
            terminal_payment.total += order.total;
            break;
          case "other_apps":
            other_apps_payment.amount++;
            other_apps_payment.total += order.total;
            break;
        }

        if (!timeline[order.creation_date]) {
          timeline[order.creation_date] = {
            date_txt: order.creation_date,
            total: order.total,
          };
        } else {
          timeline[order.creation_date].total =
            timeline[order.creation_date].total + order.total;
        }
      });
      console.log("index.tsx:182 | timeline", timeline);
      set_sales.delivery = [delivery, pickup, other_apps, for_here];
      set_sales.payment = [
        payment,
        cash_payment,
        online_payment,
        card_pos_payment,
        terminal_payment,
        other_apps_payment,
      ];
      set_sales.timeline = Object.values(timeline);
      setSales(set_sales);
    }
  }, [sales_data]);

  const onChangeDates = (changed_dates: [Date, Date]) => {
    const [start, end] = changed_dates;
    const startDate = DateTime.fromJSDate(start);
    const endDate = DateTime.fromJSDate(end);
    setDates({
      ///TODO changes string dates with new dates
      start: startDate.toISODate(),
      end: endDate.toISODate(),
      startDate: start,
      endDate: end,
    });
  };

  function renderView() {
    if (!sales_data || sales_data?.getSales?.orders.length < 1) {
      return <NoSales />;
    }
    switch (section) {
      case "orders":
        return <SalesOrders sales_data={sales_data.getSales} />;
      case "products":
        return <SalesProducts orders={sales_data.getSales.orders} />;

      default:
        return <Charts sales_data={sales} />;
    }
  }

  return (
    <main className="sales_page">
      <div className="container">
        <SalesNav />
        {loading || !sales_data ? (
          <LoaderDots fullSize={true} />
        ) : (
          <SalesResume
            sales_data={sales_data.getSales}
            selected={dates.startDate}
            startDate={dates.startDate}
            endDate={dates.endDate}
            onChange={onChangeDates}
          />
        )}
        {renderView()}
      </div>
    </main>
  );
};

const NoSales: React.FC = () => (
  <div
    className=" justify-content-center flex-column align-content-center text-center d-flex "
    id="no_order"
  >
    <h1 className="full_page_notice" style={{ marginTop: "10%" }}>
      <i className={`fa-3 text-secondary  fas fa-search-dollar`}></i>
    </h1>
    <h3 className="text-muted text-center">No hay ventas</h3>
  </div>
);

export default SalesPanel;
