import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import useSettings from "../hooks/settings";
import { OrderType } from "../models/order_model";
import { getTicket, PrintType } from "./printer/getTicket";
import { GETORDER } from "../components/pages/order/queries";

const testing = false; /// set to true for testing in console and false to print on real paper
const service_to_find = "e7810a71-73ae-499d-8c15-faa9aef0c3f2";
const characteristic_id = "bef8d6c9-9c21-4c9e-b632-bd58c1009f9f";
const device_fiters = {
  vendorId: 1046,
  services: [service_to_find],
};

type GetFiltersFun = () => USBDeviceRequestOptions | undefined;
type PrinterContextType = {
  connected: boolean;
  errors: any;
  connect: () => Promise<boolean | void>;
  print: (order_data: OrderType | string, type: PrintType) => void;
};

export const PrinterContext = React.createContext({
  connected: false,
  errors: false,
  connect: async () => {},
  print: async () => {},
} as PrinterContextType);

const PrinterContextProvider: React.FC = (props) => {
  const [settings, saveSettings] = useSettings();
  const [getOrder, { data: order_db }] = useLazyQuery<{ getOrder: OrderType }>(
    GETORDER,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [printer, setPrinter] = useState<
    USBDevice | BluetoothRemoteGATTCharacteristic | boolean
  >(testing);
  const [device_errors, setDevice_errors] = useState<any>(null);

  useEffect(() => {
    if (order_db && order_db.getOrder) {
      promtPrint(order_db.getOrder, "restaurant");
    }
    //eslint-disable-next-line
  }, [order_db]);

  const getOrderData = async (order_id: string) => {
    getOrder({
      variables: { order_id: order_id },
    });
  };

  const promtPrint = async (
    order_data: OrderType | string = {} as OrderType,
    type: PrintType = "client",
    re_print: boolean = false
  ) => {
    if (typeof order_data === "string") {
      if (settings.restaurant_copy && printer) {
        ///get order data
        getOrderData(order_data);
      }
      return;
    }
    if (order_data.products) {
      await print(order_data, type, re_print);
    }
  };

  const print = async (
    order_data: OrderType,
    type: PrintType = "client",
    re_print: boolean = false
  ) => {
    if (!printer) {
      await connectDevice();
    }
    //TODO:count duplicates
    await getTicketFun(order_data, type, re_print);
  };

  // const countSimilarProducts = (order_data: OrderType) => {
  //   let ch_text_active = "";
  //   let ch_count_active = 0;
  //   let past_product: OrderProduct;
  //   console.log("printer-context.tsx:85 | order_data", order_data);
  //   const new_products: OrderProduct[] = [];
  //   order_data.products
  //     .sort((a, b) =>
  //       parseInt(a.product.id) > parseInt(b.product.id) ? 1 : -1
  //     )
  //     .forEach((product) => {
  //       let ch_text: string = `${product.product.id.toLocaleString()}${
  //         product.ingredients_txt
  //       }${product.price.toLocaleString()}`;
  //       if (product.comments) {
  //         ch_text += product.comments;
  //       }
  //       ch_count_active += product.qty;
  //       if (ch_text === ch_text_active) {
  //         return;
  //       }
  //       ch_text_active = ch_text;
  //       if (past_product && past_product.product.id !== product.product.id) {
  //       }
  //       product.qty = ch_count_active;
  //       past_product = product;
  //       ch_count_active = 0;
  //       new_products.push(product);
  //     });
  //   order_data.products = new_products;
  //   getTicketFun(order_data);
  // };

  const getTicketFun = async (
    order_data: OrderType,
    type: PrintType = "client",
    re_print: boolean = false
  ) => {
    const get_ticket = await getTicket(order_data, settings, type, re_print);
    await printTicket(get_ticket);
  };

  const printTicket = (ticket: any) => {
    if (!printer || printer === true || ticket.has_products === false)
      return false;
    return new Promise(async (resolve, reject) => {
      if (settings.printer_type === "network") {
        /// TODO: Sent to network
        // return sent_to_network(data);
        return resolve(false);
      }
      if (!("TextEncoder" in window)) {
        alert("Sorry, this browser does not support TextEncoder...");
        return resolve(false);
      }
      if (settings.printer_type === "bluetooth" && "writeValue" in printer) {
        const MAX_DATA_SEND_SIZE = 20;
        let chunkCount = Math.ceil(
          ticket.print_text.byteLength / MAX_DATA_SEND_SIZE
        );
        let index = 0;

        while (chunkCount > 0) {
          const chunk = ticket.print_text.slice(
            index,
            index + MAX_DATA_SEND_SIZE
          );
          index += MAX_DATA_SEND_SIZE;
          chunkCount--;
          await printer.writeValue(chunk);
        }
      } else if ("configuration" in printer) {
        let endpointNumber;
        if (
          printer.configuration?.interfaces[0].alternate.endpoints[0]
            .direction === "out"
        ) {
          endpointNumber =
            printer.configuration.interfaces[0].alternate.endpoints[0]
              .endpointNumber || 1;
        } else {
          endpointNumber =
            printer.configuration?.interfaces[0].alternate.endpoints[1]
              .endpointNumber || 1;
        }
        printer
          .transferOut(endpointNumber, ticket.print_text)
          .then(() => {
            return resolve(true);
          })
          .catch((error: any) => {
            console.log("main_partner.js:2445 | error", error);
            return resolve(false);
          });
      }

      return resolve(false);
    });
  };

  const connectBluetooth = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [device_fiters],
        // acceptAllPrinter: true,
        // optionalServices: [service_to_find]
      });
      if (!device) return false;
      device.addEventListener("gattserverdisconnected", printer_disconnected);
      if (!device.gatt) return false;
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(service_to_find);
      const characteristic = await service.getCharacteristic(characteristic_id);
      const set_printer = characteristic;
      setPrinter(set_printer);
      return true;
    } catch (error: any) {
      setDevice_errors({ ...error });
      return false;
    }
  };

  const connectUSB = async () => {
    try {
      const filters_var = get_filters_var();
      console.log("printer-context.tsx:214 | filters_var", filters_var);
      const device = await navigator.usb.requestDevice(filters_var);
      ///save device slection if non selected
      saveSettings("device_vendor_id", device.vendorId);
      navigator.usb.addEventListener("disconnect", printer_disconnected);
      await device.open(); // Begin a session.
      console.log("device.open");
      await device.selectConfiguration(1);
      console.log("device.selectConfiguration");
      await device.claimInterface(0);
      console.log("device.claimInterface");
      return setPrinter(device);
    } catch (error: any) {
      console.log("printer-context.tsx:226 | error", error);
      setDevice_errors({ ...error });
      ///delete previous USB Vendor ID
      saveSettings("printer_vendor_id", "");
    }
  };

  const get_filters_var: GetFiltersFun = () => {
    const filters_var: USBDeviceRequestOptions = {
      filters: [],
    };
    if (settings.printer_vendor_id) {
      filters_var.filters.push({
        vendorId: settings.printer_vendor_id,
        //@ts-ignore next line
        services: [service_to_find],
      });
    } else {
      // filters_var.filters.push({ interfaceClass: 7 });
      // filters_var.acceptAllDevices: true,
      // filters_var.optionalServices: [service_to_find],
    }

    return filters_var;
  };

  const connectDevice = async () => {
    if (settings.printer_type === "bluetooth") {
      return await connectBluetooth();
    }
    ///usb default
    return await connectUSB();
  };

  const printer_disconnected = () => {
    setPrinter(false);
  };

  return (
    <PrinterContext.Provider
      value={{
        connect: connectDevice,
        print: promtPrint,
        errors: device_errors,
        connected: printer ? true : false,
      }}
    >
      {props.children}
    </PrinterContext.Provider>
  );
};

export default PrinterContextProvider;
