import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import { createUploadLink } from "apollo-upload-client";

import { getToken, onMessageListener, NotificationPayload } from "./firebase";
import { PrinterContext } from "./context/printer-context";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/font-awesome.css";
import "./css/foo-icons.css";
import "./css/app.css";

import Header from "./components/header";
import NavMob from "./components/nav/NavMob";
import ScrollToTop from "./middleware/scrollTop";
import SideNav from "./components/nav/side_nav";
import { FullPageLoader } from "./middleware/loaders";

//pages
import Orders from "./components/pages/orders";
import PointOfSale from "./components/pages/pos";
import Account from "./components/pages/account";
import Products from "./components/pages/products";
import CashOuts from "./components/pages/cash_cuts";
import Sales from "./components/pages/sales";
import LoginPage from "./components/pages/login";
import SignupPage from "./components/pages/signup";
import LogoutPage from "./components/pages/logout";
import Order from "./components/pages/order";
import DashNav from "./components/pages/dash_navigation";
import Users from "./components/pages/users";
import Branches from "./components/pages/branches";
import WelcomePage from "./components/pages/welcome";
import BranchName from "./components/common/branchname";
import { User } from "./models/user_model";
import { cache } from "./middleware/cache";
import AuthContextProvider from "./context/auth-context";
import PrinterProvider from "./context/printer-context";

const Layout = () => {
  const [isAuth, setIsAuth] = useState(false);
  const userData: User = JSON.parse(localStorage.getItem("user") || "{}");
  const [sideShow, setSideShow] = useState(false);

  useEffect(() => {
    if (userData.id) {
      if (userData.privileges === 0) {
        if (window.location.pathname !== "/logout") {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        setIsAuth(true);
      }
    }
  }, [userData]);

  const showSide = () => {
    setSideShow(!sideShow);
  };

  if (!isAuth) {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Redirect to="/login" />
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <main>
        <ToastContainer />
        <div className="row main_container">
          <div
            className={`col-lg-2 ${sideShow ? "" : "hidden"}`}
            id="sideNavCont"
          >
            <SideNav active={sideShow} showside={showSide} />
          </div>
          <div className={`col-lg-${sideShow ? 10 : 12}`}>
            {["localhost", "app.dev.foopos.mx"].includes(
              window.location.hostname
            ) && (
              <div className="alert alert-danger" role="alert">
                Development Server
              </div>
            )}
            <Header sideShow={sideShow} />
            <Switch>
              <Route exact path="/" component={Orders} />
              <Route path="/point_of_sale/:order_id?" component={PointOfSale} />
              <Route path="/account" component={Account} />
              <Route path="/products" component={Products} />
              <Route path="/cash_outs_panel" component={CashOuts} />
              <Route path="/sales/:section?" component={Sales} />
              <Route path="/users" component={Users} />
              <Route path="/branches" component={Branches} />
              <Route path="/order/:order_id" component={Order} />
              <Route path="/dash_navigation" component={DashNav} />
              <Route path="/logout" component={LogoutPage} />
              <Route path="/welcome" component={WelcomePage} />
              <Redirect to="/" />
            </Switch>
          </div>
        </div>
      </main>
      <NavMob />
      {userData.branch ? <BranchName branch={userData.branch} /> : null}
    </React.Fragment>
  );
};

const App = () => {
  const printer = useContext(PrinterContext);
  const [showSplash, setShowSplash] = useState(true);
  const graph_url = process.env.REACT_APP_GRAPH_URL;
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState<NotificationPayload>(
    {} as NotificationPayload
  );

  const api = new ApolloClient({
    uri: graph_url,
    cache,
    connectToDevTools: process.env.NODE_ENV === "development",
    link: createUploadLink({
      uri: graph_url,
      headers: {
        authorization: localStorage.getItem("token")
          ? `Bearer ${localStorage.getItem("token")}`
          : "",
      },
    }),
    headers: {
      authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 1500);
    if ("Notification" in window) {
      getToken(setTokenFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTokenFound) {
      ////save token to DB
      console.log("App.tsx:71 | isTokenFound", isTokenFound);
    }
  }, [isTokenFound]);

  useEffect(() => {
    if (notification.show) {
      switch (notification.type) {
        case "danger":
          toast.error(notification.title);
          break;
        case "success":
          toast.success(notification.title);
          break;
        case "warning":
          toast.warn(notification.title);
          break;
        case "info":
          toast.info(notification.title);
          break;
        default:
          toast(notification.title);
          break;
      }
    }
  }, [notification]);

  if ("Notification" in window) {
    onMessageListener()
      .then((payload: any) => {
        const notification_data: NotificationPayload = payload.notification;
        setNotification({
          show: true,
          title: notification_data.title,
          body: notification_data.body,
          type: notification_data.type,
          icon: notification_data.icon,
        });
        const new_order_id = notification_data.body.replace(/\D/g, "");
        if (new_order_id) {
          printer.print(new_order_id, "restaurant");
        }
        console.log("App.tsx:137 | payload", payload);
      })
      .catch((err) => console.log("failed: ", err));
  }

  if (showSplash || !api) {
    return <FullPageLoader show />;
  }
  return (
    <ApolloProvider client={api}>
      <AuthContextProvider>
        <PrinterProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Layout />
            </ScrollToTop>
          </BrowserRouter>
        </PrinterProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
};

export default App;
