import React, { useState, useEffect } from "react";
import {
  UserRole,
  AccessType,
  SubUserType,
} from "../../../models/subuser_model";
import { Link, useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  createSubuserVariables,
  CREATE_SUBUSER,
  EditSubuserInput,
  editSubuserVariables,
  EDIT_SUBUSER,
  GET_SUBUSER,
  GET_SUBUSERS,
  SubuserInput,
} from "./queries";
import { User } from "../../../models/user_model";
import { ErrorType } from "../../../models/errors";
import { GET_BRANCHES } from "../account/queries";
import { Branch } from "../../../models/partner_model";

const userTypes = [
  {
    value: UserRole.admin,
    title: "Administrador",
    description: "Cuenta con todos los privilegios igual que la cuenta maestra",
  },
  {
    value: UserRole.cook,
    title: "Cocinero",
    description: "Puede ver las órdenes y modificar el estado de los pedidos",
  },
  {
    value: UserRole.waiter,
    title: "Mesero",
    description: "Puede hacer pedidos. No recibe cobros",
  },
  {
    value: UserRole.delivery,
    title: "Repartidor",
    description: "El repartidor puede ver los pedidos y realizar los cobros",
  },
  {
    value: UserRole.cashier,
    title: "Cajero",
    description: "Puede hacer pedidos y recibir cobros",
  },
  {
    value: UserRole.editor,
    title: "Editor",
    description:
      "Puede modificar información de productos y la información del negocio como direcciones, logo y precios",
  },
  {
    value: UserRole.manager,
    title: "Gerente",
    description:
      "Tiene acceso a todos los privilegios excepto para agregar usuarios y modificar la información de pago y cobros dentro de Foo dentro de la sucursal que selecciones",
  },
  {
    value: UserRole.books,
    title: "Contador",
    description:
      "Puede ver toda la información de ventas del negocio. No puede hacer pedidos ni recibir cobros",
  },
];

const additionalAccess = [
  {
    value: AccessType.email_notify,
    title: "Recibir notificaciones de pedidos",
    description:
      "Enviar al usuario correos y mensajes de pedidos nuevos online",
  },
  {
    value: AccessType.change_prices,
    title: "Modificar Precios",
    description:
      "Puede modificar precios de productos a la hora de levantar pedidos",
  },
  {
    value: AccessType.cancel_orders,
    title: "Editar y Cancelar Pedidos",
    description: "Permite cancelar y editar órdenes",
  },
  {
    value: AccessType.view_branches,
    title: "Ver sucursales",
    description:
      "Permite cambiar de sucursal para hacer pedidos y ver reportes",
  },
  {
    value: AccessType.open_drawer,
    title: "Abrir Caja de Monedas",
    description: "Restringe el acceso libre a la caja de monedas",
  },
  {
    value: AccessType.view_past_orders,
    title: "Ver pedidos anteriores",
    description:
      "Permite al usuario ver los ultimos pedidos en la pantalla de órdenes",
  },
  {
    value: AccessType.cash_outs,
    title: "Ver cortes de caja",
    description:
      "Permite al usuario ver los movimientos de efectivo y los reportes del día",
  },
];

type Params = {
  id: string;
};

type QueryUser = {
  getSubuser: SubUserType;
};

const UserForm: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const [subUser, setSubUser] = useState<SubUserType>({} as SubUserType);
  const [error, setError] = useState<ErrorType | null>(null);
  const { data: branches_data } =
    useQuery<{ getBranches: Branch[] }>(GET_BRANCHES);
  const [createSubuser, { loading }] = useMutation<
    User,
    createSubuserVariables
  >(CREATE_SUBUSER, {
    refetchQueries: [{ query: GET_SUBUSERS }],
  });
  const [editSubuser, { loading: loadingEdit }] = useMutation<
    User,
    editSubuserVariables
  >(EDIT_SUBUSER, {
    refetchQueries: [
      { query: GET_SUBUSERS },
      { query: GET_SUBUSER, variables: { id: +id } },
    ],
  });
  const [getSubuser, { data: currentUser }] = useLazyQuery<QueryUser>(
    GET_SUBUSER,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const updatePermissions = (permission: AccessType) => {
    const set_user = { ...subUser };
    if (!set_user.permissions) {
      return;
    }
    if (set_user.permissions.includes(permission)) {
      set_user.permissions = set_user.permissions.filter(
        (e) => e !== permission
      );
    } else {
      set_user.permissions = [...set_user.permissions, permission];
    }
    setSubUser(set_user);
  };

  useEffect(() => {
    if (currentUser) {
      setSubUser({
        ...currentUser.getSubuser,
        branch_id: currentUser.getSubuser.branch?.id,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (id) {
      getSubuser({ variables: { id: +id } });
    }
  }, [id, getSubuser]);
  const postSubuser = async () => {
    if (!subUser.email) {
      setError({
        message: "Favor de agregar Email del usuario",
      });
      return;
    } else if (!subUser.role) {
      setError({
        message: "Favor de agregar seleccionar el Rol",
      });
      return;
    } else if (!subUser.quickPass) {
      setError({
        message: "Agrega una clave de acceso rápido",
      });
      return;
    } else if (!subUser.branch_id) {
      setError({
        message: "Selecciona una sucursal",
      });
      return;
    }
    if (!id) {
      const data: SubuserInput = {
        email: subUser.email,
        quickPass: subUser.quickPass,
        role: subUser.role,
        permissions: subUser.permissions || [],
      };
      try {
        await createSubuser({ variables: { data } });
        history.push("/users");
      } catch (catch_error: any) {
        if (catch_error.message) {
          setError({
            message: catch_error.message,
          });
        } else {
          setError({
            message: catch_error.message,
          });
        }
      }
    } else {
      const data: EditSubuserInput = {
        id: +id,
        email: subUser.email,
        quickPass: subUser.quickPass,
        role: subUser.role,
        permissions: subUser.permissions || [],
      };
      try {
        await editSubuser({ variables: { data } });
        history.push("/users");
      } catch (catch_error: any) {
        if (catch_error.message) {
          setError({
            message: catch_error.message,
          });
        } else {
          setError({
            message: catch_error.message,
          });
        }
      }
    }
  };

  const handleQuickPass = (ev: { target: HTMLInputElement }) => {
    const set_user = { ...subUser };
    const value = ev.target.value;
    if (value.length > 4) {
      return;
    }
    set_user.quickPass = value;
    setSubUser(set_user);
  };

  const handleEmail = (ev: { target: HTMLInputElement }) => {
    const set_user = { ...subUser };
    const value = ev.target.value;
    set_user.email = value;
    setSubUser(set_user);
  };

  const handleRole = (ev: { currentTarget: HTMLElement }) => {
    const set_user = { ...subUser };
    const value = ev.currentTarget.dataset.id as UserRole;
    set_user.role = value;
    setSubUser(set_user);
  };

  const changeBranch = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const branch_id = e.target.value;
    const set_user = { ...subUser };
    set_user.branch_id = branch_id;
    setSubUser(set_user);
  };

  return (
    <div className="container">
      <div className="row my-3 justify-content-between">
        <div className="col-6 mt-lg-5">
          <h1>Invitar a nuevo usuario </h1>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7">
          <h4>Email del usuario</h4>
          <form>
            <div className="form-group ">
              <input
                onChange={handleEmail}
                value={subUser.email}
                type="email"
                className="form-control"
                id="account_email"
                aria-describedby="emailHelp"
              />
              <div className="invalid-feedback">
                Favor de ingresar un correo real
              </div>
            </div>
            <div className="form-group ">
              <h4>Sucursal Permitida</h4>
              <select
                className="form-control form_required"
                name="active_branch"
                autoComplete="off"
                onChange={changeBranch}
                value={subUser.branch_id || ""}
                required
                id="active_branch"
              >
                <option value="false">Selecciona Sucursal</option>
                {branches_data && branches_data.getBranches
                  ? branches_data.getBranches.map((branch) => (
                      <option value={branch.id} key={`branch.id_${branch.id}`}>
                        {branch.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <h4 className="mb-0">Acceso del usuario </h4>
            <small className="text-muted mb-3 d-block">
              selecciona el rol del usuario
            </small>
            {userTypes.map(({ value, title, description }) => (
              <div className="form-check" key={value}>
                <input
                  className="form-check-input m-0 user_access user_access_sum hover_pointer"
                  type="radio"
                  checked={value === subUser.role}
                  data-id={value}
                  onChange={handleRole}
                  value={value}
                />
                <label
                  className="ml-4 form-check-label"
                  htmlFor={`radio-${value}`}
                  data-id={value}
                  onClick={handleRole}
                >
                  {title}
                </label>
                <p
                  className="ml-4 mt-0 hover_pointer"
                  data-id={value}
                  onClick={handleRole}
                >
                  {description}
                </p>
              </div>
            ))}
          </form>
        </div>
        <div className="col-lg-4">
          <div className="form-group">
            <h4>Clave de Acceso Rápido</h4>
            <input
              type="text"
              className="form-control w-50"
              id="quick_pass"
              value={subUser.quickPass}
              onChange={handleQuickPass}
            />
            <span className="text-secondary small">4 dígitos</span>
            <div className="invalid-feedback" id="pass_inuse"></div>
          </div>
          <h4 className="mb-0">Permisos Adicionales</h4>
          <small className="text-muted mb-3 d-block">Opcional</small>
          {additionalAccess.map(({ title, value, description }) => (
            <div className="form-check">
              <input
                className="form-check-input m-0 user_access user_access_optional hover_pointer"
                type="checkbox"
                onChange={(e) =>
                  updatePermissions(e.target.value as AccessType)
                }
                checked={subUser.permissions?.includes(value)}
                name={`check_${value}`}
                id="user_access_email_notify"
                value={value}
              />
              <label
                className="ml-4 form-check-label"
                htmlFor={`check_${value}`}
                onClick={() => updatePermissions(value)}
              >
                {title}
              </label>
              <p
                className="ml-4 mt-0 hover_pointer"
                onClick={() => updatePermissions(value)}
              >
                {description}
              </p>
            </div>
          ))}
          {error ? (
            <div className="alert alert-danger">{error.message}</div>
          ) : null}
          <div className="d-flex justify-content-end mt-5">
            <Link to="/users" className="btn btn-outline-secondary mr-4">
              Cancelar
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading || loadingEdit}
              onClick={postSubuser}
            >
              {subUser.id
                ? loading || loadingEdit
                  ? "Guardando..."
                  : "Guardar Cambios"
                : loading || loadingEdit
                ? "Enviando invitación"
                : "Enviar invitación"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
