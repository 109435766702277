import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { LOGINQUERY } from "./queries";
import foo_logo from "../../assets/foo_mascot_chef_hat@2x.svg";

const LoginPage = () => {
  const email = useRef(null);
  const password = useRef(null);
  const [loginUser, { loading, error: loginError }] = useMutation(LOGINQUERY);

  const loginHandler = async () => {
    const email_val = email.current.value;
    const password_val = password.current.value;
    if (!email_val || !password_val) {
      return false;
    }

    const { data: new_user_data } = await loginUser({
      variables: {
        email: email_val,
        pass: password_val,
      },
    });
    if (new_user_data.login) {
      const token = new_user_data.login.token;
      const user = { ...new_user_data.login.user };
      const settings = { ...new_user_data.login.settings };
      const settings_obj = {};
      Object.keys(settings).forEach((setting_key) => {
        const setting = settings[setting_key];
        const pref_key = setting.pref_key;
        delete setting.pref_key;
        if (setting.pref_value === "true" || setting.pref_value === "false") {
          setting.pref_value = setting.pref_value === "true" ? true : false;
        } else if (!isNaN(setting.pref_value)) {
          setting.pref_value = parseInt(setting.pref_value);
        }
        settings_obj[pref_key] = setting.pref_value;
      });
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("settings", JSON.stringify(settings_obj));
      localStorage.setItem("token", token);
      window.location.reload();
    }
  };

  const enterPressed = (ev) => {
    var code = ev.keyCode || ev.which;
    if (code === 13) {
      //13 is the enter keycode
      loginHandler();
    }
  };

  return (
    <main>
      <div className="container login_div">
        <div className="row justify-content-center">
          <div className="col-10 col-sm-5 col-lg-3">
            <div className="row text-center justify-content-center">
              <div className="col-6">
                <a href="/">
                  <img src={foo_logo} className="width-100" alt="Foo Logo" />
                </a>
              </div>
              <div className="col-12">
                <div className="h1">Log In</div>
                {loginError && !loading ? (
                  <div className="alert alert-warning">
                    <i className="fas fa-exclamation-triangle shake_anim"></i>{" "}
                    No se encontro el usuario o no coincide la contraseña
                  </div>
                ) : null}
              </div>
            </div>

            <form className="form-signin" method="post">
              <div className="form-group">
                {/*<label htmlFor="login_data">Correo o Teléfono</label> */}
                <input
                  type="text"
                  name="login_data"
                  data-testid="username-input"
                  className="form-control"
                  ref={email}
                  onKeyPress={enterPressed}
                />
              </div>
              <div className="form-group">
                {/* <label for="exampleInputPassword1">Password</label> */}
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  ref={password}
                  placeholder="Password"
                  onKeyPress={enterPressed}
                />
              </div>
              <button
                type="button"
                className="btn btn-raised btn-primary btn-block btn_xs_lg "
                onClick={loginHandler}
                disabled={loading}
              >
                {loading ? "Iniciando..." : "Iniciar Sesión"}
              </button>

              <p>
                <small>
                  <a href="/es/partner/forgot">Olvidó su contraseña?</a>
                </small>
              </p>
              <p>
                <small className="text-dark">
                  Sin cuenta? <Link to="/signup">Crear cuenta de Partner</Link>
                </small>
              </p>

              <br />
              <br />
              <br />
              <a
                href="https://www.fooapp.mx/es/pages/login"
                className="btn btn-sm btn-block btn-link"
              >
                <i className="fas fa-undo-alt text-primary"></i> Log in Para
                Usuarios
              </a>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
