import React, { useEffect, useState, useContext } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";

import {
  MAKEORDER,
  GETORDER,
  GETADDRESS,
  GETCLIENTS,
  SAVEADDRESS,
  SAVEUSER,
} from "./queries";
import { GETTABLES } from "../orders/queries";
import { PrinterContext } from "../../../context/printer-context";
import useSettings from "../../../hooks/settings";
import useQuery from "../../../hooks/useQuery";
import { hasIngredient } from "../../../middleware/product_functions";
import NavMob from "../../nav/headerMob";
import Products from "./products";
import SideUI from "./side";
import BottomCta from "./bottom_cta";
import {
  ModalIng,
  ModalOptions,
  ModalTables,
  ModalSizes,
  ModalChangePrice,
  ModalComments,
  AddUserModal,
  AddUserDataModal,
  AddAddressModal,
  AddNewAddressModal,
  ModalOrder,
  ProductActions,
  ShippingPriceModal,
} from "../../modals/";

const PointOfSale = ({ history }) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  let timer = null;
  const activeBranch = user.branch ? user.branch.id : 0;
  const printer = useContext(PrinterContext);
  const urlQuery = useQuery();
  const [settings] = useSettings();
  const tables = JSON.parse(localStorage.getItem("tables"));
  const menu_all = JSON.parse(localStorage.getItem("menu_all"));
  const { order_id } = useParams();
  const [getOrderDb, { data: orderDb }] = useLazyQuery(GETORDER, {
    fetchPolicy: "no-cache",
  });

  const [getClientAddress, { data: clientAddress, loading: loadingAddress }] =
    useLazyQuery(GETADDRESS, {
      fetchPolicy: "no-cache",
    });
  const [getClients, { data: clients }] = useLazyQuery(GETCLIENTS, {
    fetchPolicy: "no-cache",
  });
  const [getTables, { data: tablesDb }] = useLazyQuery(GETTABLES, {
    fetchPolicy: "no-cache",
  });
  const [order, setOrder] = useState({
    total: 0,
    delivery: false,
    products: [],
    user: { id: null },
  });
  const [product, setProduct] = useState(null);
  const [tiggerAdded, setTiggerAdded] = useState(null);
  const [options_obj, setOptions_obj] = useState({
    total: 0,
    count: 0,
  });
  const [modals, setModals] = useState({});
  const [buyer, setBuyer] = useState(null);
  const [comments, setComment] = useState(null);
  const [makeOrderMutation, { loading: making_order }] = useMutation(MAKEORDER);
  const [saveUserMutation] = useMutation(SAVEUSER);
  const [saveUserAddressMutation, { loading: saving_address }] =
    useMutation(SAVEADDRESS);

  useEffect(() => {
    ///loook for active order
    const activeOrder = JSON.parse(localStorage.getItem("activeOrder"));
    if (
      activeOrder &&
      activeOrder.products &&
      activeOrder.products.length > 0 &&
      activeOrder.total > 0
    ) {
      setOrder({ ...activeOrder });
    }
    if (urlQuery.get("table")) {
      const set_order = { ...order };
      set_order.table_id = parseInt(urlQuery.get("table"));
      setOrder({ ...set_order });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tablesDb) {
      localStorage.setItem("tables", JSON.stringify(tablesDb.getTables));
    }
  }, [tablesDb]);

  useEffect(() => {
    ///reach for orders
    if (order_id) {
      getOrderDb({
        variables: {
          order_id: parseInt(order_id),
        },
      });
    }
    getTables({
      variables: {
        branch: +activeBranch,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id, activeBranch]);

  useEffect(() => {
    ///reach for orders
    if (orderDb) {
      const order_data = orderDb.getOrder;
      if (order_data.client_comments) {
        setComment(order_data.client_comments);
      }
      if (order_data.client) {
        setBuyer(order_data.client);
      }
      const products = order_data.products.map((item) => {
        return {
          ...item,
          id: item.product.id,
          order_id: item.id,
          ingredientsSel:
            item.ingredients.length > 0 ? item.ingredients.split(",") : [],
          text: item.ingredients_txt || "",
          ingredients_txt: item.ingredients_txt || "",
          qty: item.qty,
        };
      });
      delete order_data.products;
      order_data.make_order = false;
      order_data.delivery = order_data.delivery_type;
      order_data.edited = true;
      if (order_data.table && order_data.table.id) {
        order_data.table_id = parseInt(order_data.table.id);
      } else if (order_data.delivery === "delivery" && order_data.address) {
        order_data.delivery_id = order_data.address.id;
      }

      setOrder({ ...order_data, products });
    }
  }, [orderDb]);

  useEffect(() => {
    ///prompt make order on mob
    if (
      (order && order.make_order) ||
      (order.delivery !== false && !order.edited)
    ) {
      promptMakeOrder();
    } else if (!order_id) {
      ///save in localstorage for future use
      const activeOrder = {
        total: order.total,
        delivery: false,
        products: [...order.products],
        user: { id: null },
      };
      localStorage.setItem("activeOrder", JSON.stringify(activeOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (tiggerAdded) {
      setTimeout(() => {
        setTiggerAdded(null);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiggerAdded]);

  useEffect(() => {
    ///prompt add product fast
    if (product && product.add_product) {
      addProductOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const selectProduct = (ev) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const selected_product_key = Object.keys(menu_all.products).find((key) => {
      const product = menu_all.products[key];
      return product.id === product_id;
    });
    const selected_product = menu_all.products[selected_product_key];
    const has_options = selected_product.options
      ? selected_product.has_option
      : 0;

    const has_sizes = selected_product.sizes
      ? selected_product.sizes.length
      : 0;
    const productState = {
      ...selected_product,
      id: product_id,
      price: selected_product.price,
      qty: 1,
      title: selected_product.title,
      text: "",
      text_ing: [],
      ingredientsSel: selected_product.ingredients.map((item) => item.id),
      has_options,
      has_sizes,
      zone_id: +selected_product.zone_id,
    };
    if (has_options) {
      productState.text_opt = [];
      productState.optionsSel = {};
    }
    if (has_sizes) {
      productState.sizes = {
        ...selected_product.sizes,
      };
    }

    // if (option) {
    //   productState.price = product_price + option_price;
    // }
    const set_options_obj = { ...options_obj };
    set_options_obj.count++;
    setOptions_obj(options_obj);
    if (has_sizes) {
      setProduct({ ...productState });
      openModal("sizes");
    } else if (has_options) {
      setProduct({ ...productState });
      openModal("options");
    } else {
      productState.add_product = true;
      setProduct({ ...productState });
    }
  };

  const selectIng = (ev) => {
    const product_key = +ev.currentTarget.dataset.product_key;
    const order_product = order.products[product_key];
    const product_id = order_product.id;
    const selected_product_key = Object.keys(menu_all.products).find((key) => {
      const product = menu_all.products[key];
      return product.id === product_id;
    });
    const selected_product = menu_all.products[selected_product_key];
    const has_options = selected_product.options
      ? selected_product.options.length
      : 0;

    const has_sizes = selected_product.sizes
      ? selected_product.sizes.length
      : 0;
    const productState = {
      ...selected_product,
      key: product_key,
      id: product_id,
      price: order_product.price,
      price_base: order_product.price / order_product.qty,
      qty: order_product.qty,
      title: order_product.title,
      text: order_product.text,
      text_ing: order_product.text_ing,
      ingredientsSel: [...order_product.ingredientsSel],
      has_options,
      has_sizes,
      zone_id: +order_product.zone_id,
      text_opt: order_product.text_opt,
      optionsSel: order_product.optionsSel,
      size: order_product.size,
    };
    if (has_sizes) {
      productState.sizes = {
        ...selected_product.sizes,
      };
    }

    const set_options_obj = { ...options_obj };
    set_options_obj.count++;
    setProduct({ ...productState });
    openModal("ingredients");
  };

  const openModal = (type, value = false) => {
    const set_modals = {};
    set_modals[type] = value || true;
    setModals(set_modals);
  };

  const addProductOrder = () => {
    const set_order = { ...order };
    const set_product = { ...product };
    const set_modals = {
      ingredients: false,
    };

    set_product.price = set_product.price * set_product.qty;
    delete set_product.ingredients;
    delete set_product.options;
    if (!set_order.products) {
      set_order.products = [];
    }
    if (!set_order.total) {
      set_order.total = 0;
    }
    set_order.products.push(set_product);
    set_order.total = set_order.total + product.price * product.qty;
    setOrder({ ...set_order });
    setModals({ ...set_modals });
    setTiggerAdded(set_product.id);
    setProduct(null);
  };

  const editProductOrder = () => {
    const set_order = { ...order };
    const set_product = { ...product };
    const set_modals = {
      ingredients: false,
    };
    set_product.price = set_product.price_base * set_product.qty;
    delete set_product.ingredients;
    delete set_product.options;
    set_order.products[set_product.key] = set_product;
    set_order.total = set_order.products.reduce(
      (accumulator, item) => accumulator + item.price,
      0
    );
    setOrder({ ...set_order });
    setModals({ ...set_modals });
    setProduct(null);
  };

  const removeIngredientHandler = (element) => {
    const ingId = element.currentTarget.dataset.ing;
    const productState = { ...product };
    const targetIng = product.ingredients.find((item, key) => {
      return item.id === ingId;
    });
    const has_ingredient = hasIngredient(
      productState.ingredientsSel,
      targetIng.id,
      true
    );
    if (has_ingredient >= 0) {
      productState.text_ing.push(targetIng.name);
      productState.ingredientsSel.splice(has_ingredient, 1);
    } else {
      const index = productState.text_ing.indexOf(targetIng.name);
      productState.text_ing.splice(index, 1);
      productState.ingredientsSel.push(targetIng.id);
    }

    productState.text = concatText(
      productState.text_ing,
      productState.text_opt
    );
    setProduct({ ...productState });
  };
  const modalHandler = (type) => {
    const curreent_modals = { ...modals };
    const new_modals = {};
    new_modals[type] = !curreent_modals[type];
    setModals({ ...new_modals });
    setProduct(null);
    setComment(null);
  };

  const openProductsActions = (ev) => {
    const product_id = ev.currentTarget.dataset.product_key;
    if (!product_id) {
      return;
    }
    openModal("productactions", product_id);
  };

  const handleQty = (ev) => {
    const value = +ev.currentTarget.value;
    const productstate = { ...product };
    productstate.qty = value;
    setProduct({ ...productstate });
  };
  const handleSum = () => {
    const productstate = { ...product };
    productstate.qty = productstate.qty + 1;
    setProduct({ ...productstate });
  };
  const handleLess = () => {
    const productstate = { ...product };
    if (productstate.qty === 1) return false;
    productstate.qty = productstate.qty - 1;
    setProduct({ ...productstate });
  };

  const handleDelivery = (elem) => {
    const delivery = elem.currentTarget.dataset.type;
    const set_order = { ...order };
    set_order.delivery = delivery;
    setOrder({ ...set_order });
  };

  const handleNewAddress = async (place) => {
    console.log(place);
    if (!place || !place.address_components) return;
    ///format address
    const selected_address = {};
    const selected_buyer = { ...buyer };
    place.address_components.forEach((address_item) => {
      const addres_type = address_item.types[0] || false;
      if (!addres_type) return;
      selected_address[addres_type] = address_item.long_name;
    });
    const addressInput = {
      address: place.formatted_address,
      zip: selected_address.postal_code
        ? parseInt(selected_address.postal_code)
        : 0,
      city: selected_address.locality || null,
      state: selected_address.administrative_area_level_1 || null,
      location: place.geometry
        ? `${place.geometry.location.lat()},${place.geometry.location.lng()}`
        : null,
    };
    if (selected_buyer.address) {
      selected_buyer.address = { ...selected_buyer.address, ...addressInput };
    } else {
      selected_buyer.address = addressInput;
    }
    selected_buyer.google_maps = true;

    setBuyer(selected_buyer);
  };

  const hadleAddressValue = (ev) => {
    const selected_buyer = { ...buyer };
    const value = ev.target.value;
    const type = ev.target.dataset.type;
    if (!selected_buyer.address) {
      selected_buyer.address = {};
    }
    if (type === "shipping_cost") {
      selected_buyer.address[type] = parseFloat(value);
    } else {
      selected_buyer.address[type] = value;
    }
    setBuyer(selected_buyer);
  };

  const handleBuyerType = async (ev) => {
    const current_buyer = { ...buyer };
    const type = ev.target.dataset.type;
    const value = ev.target.value;

    current_buyer[type] = value;
    setBuyer(current_buyer);
  };

  const saveUser = async () => {
    if (!buyer.phone) return;
    try {
      const userInput = {
        id: buyer.id || null,
        name: buyer.name,
        lastname: buyer.lastname,
        phone: buyer.phone,
        email: buyer.email || null,
      };
      const new_client = await saveUserMutation({
        variables: { user: userInput, order_id: parseInt(order_id) },
      });
      setBuyer({ ...new_client.data.saveUser });
      modalHandler("adduseraddress");
      getClientAddress({
        variables: {
          client_id: parseInt(new_client.data.saveUser.id),
          branch_id: parseInt(activeBranch),
        },
      });
    } catch (error) {
      console.log("index.js:74 | error", error);
    }
  };

  const saveUserAddress = async () => {
    const selected_buyer = { ...buyer };
    const set_order = { ...order };
    if (
      !selected_buyer.google_maps &&
      typeof selected_buyer.address.shipping_cost === "undefined"
    ) {
      ///no address was returned
      deliveryPricePromt();
      return;
    }
    selected_buyer.address.user_id = parseInt(selected_buyer.id);
    selected_buyer.address.branch_id = parseInt(+activeBranch);
    if (!selected_buyer.address.city) {
      ////TODO: change with user active branch city
      ///https://github.com/Foo-App/foo-server-v2/issues/64
      selected_buyer.address.city = "Chihuahua";
    }

    if (!selected_buyer.address.zip) {
      ////TODO: change with user active branch city
      ///https://github.com/Foo-App/foo-server-v2/issues/64
      selected_buyer.address.zip = 31200;
    }
    if (!selected_buyer.address.state) {
      ////TODO: change with user active branch city
      ///https://github.com/Foo-App/foo-server-v2/issues/64
      selected_buyer.address.state = "Chihuahua";
    }
    const address_new = await saveUserAddressMutation({
      variables: { address: selected_buyer.address },
    });
    selected_buyer.address = { ...address_new.data.setUserAddress };
    set_order.delivery_id = parseInt(selected_buyer.address.id);
    set_order.shipping_cost = parseInt(selected_buyer.address.shipping_cost);
    if (set_order.shipping_cost > 0) {
      set_order.total += set_order.shipping_cost;
    }
    setBuyer(selected_buyer);
    setOrder(set_order);
  };

  const deliveryPricePromt = () => {
    setModals({
      shippingpricemodal: true,
    });
  };

  const OptionsHandler = (element) => {
    const optId = element.currentTarget.dataset.ing;
    const productState = { ...product };
    let option_key = 0;
    const targetOpt = product.options.find((item, key) => {
      option_key = key;
      return item.id === optId;
    });

    if (productState.optionsSel[targetOpt.id]) {
      const index = productState.text_opt.indexOf(targetOpt.name);
      productState.text_opt.splice(index, 1);
      productState.price = productState.price - targetOpt.price_mod;
      delete productState.options[option_key].active;
      delete productState.optionsSel[targetOpt.id];
    } else {
      productState.text_opt.push(targetOpt.ingredient.name);
      productState.options[option_key].active = true;
      productState.price = productState.price + targetOpt.price_mod;
      productState.optionsSel[targetOpt.id] = targetOpt;
    }
    productState.text = concatText(
      productState.text_ing,
      productState.text_opt
    );

    ////if maxium options selected move to nex panel
    if (
      Object.keys(productState.optionsSel).length === productState.has_options
    ) {
      productState.add_product = true;
      openModal(null);
    }
    setProduct({ ...productState });
  };

  const SizesHandler = (element) => {
    const sizeId = element.currentTarget.dataset.ing;
    const productState = { ...product };
    productState.size = { ...product.sizes[sizeId] };

    productState.text = concatText(
      productState.text_ing,
      productState.text_opt,
      productState.size.name
    );
    if (productState.size.price > 0) {
      productState.price = productState.size.price;
    }

    ////if maxium options selected move to nex panel
    if (productState.has_options) {
      setProduct({ ...productState });
      openModal("options");
    } else {
      productState.add_product = true;
      openModal(null);
      setProduct({ ...productState });
    }
  };

  const concatText = (ing, opt, size = false) => {
    let size_text = "";
    if (size) {
      size_text = size + " | ";
    } else if (product.size) {
      size_text = product.size.name + " | ";
    }
    const ing_text = ing && ing.length > 0 ? "Sin " + ing.join(", ") : "";
    const opt_text = opt ? opt.join(" ") + " | " : "";
    return size_text + opt_text + ing_text;
  };
  const promptMakeOrder = async () => {
    const set_order = { ...order };
    if (!set_order.products || set_order.products.length < 1) {
      ///avoid empty orders
      set_order.delivery = false;
      setOrder(set_order);
      return false;
    }
    if (set_order.edited) {
      makeOrder();
    } else if (set_order.delivery === "for_here") {
      ///show tables if any
      if (!set_order.table_id && tables && tables.length > 0) {
        setModals({
          tables: true,
        });
      } else {
        makeOrder();
      }
    } else if (set_order.delivery === "delivery" && !set_order.delivery_id) {
      //show user selection
      getClients({
        variables: {
          limit: 5,
        },
      });
      setBuyer({});
      setModals({
        adduser: true,
      });
    } else {
      ///is pickup, make order
      makeOrder();
    }
  };

  const makeOrder = async (ev) => {
    //avoid resending order
    if (making_order) return;
    ///Save order to db
    const order_products = order.products.map((product) => {
      const orderProduct = {
        id: parseInt(product.id),
        dbId: product.order_id ? parseInt(product.order_id) : undefined,
        price: product.price,
        quantity: product.qty,
        ingredients_txt: product.text,
        ingredients: "",
        comments: product.comments,
      };
      if (product.size) {
        orderProduct.size = parseInt(product.size.id);
      }
      if (product.ingredientsSel) {
        orderProduct.ingredients = product.ingredientsSel.join(",");
      }
      if (product.text_opt) {
        orderProduct.ingredients_opt = product.text_opt.join(" ");
      }
      return orderProduct;
    });
    const order_input = {
      delivery: order.delivery,
      comments: order.comments,
      total: order.total,
      products: [...order_products],
      shipping_cost: 0,
      // user_id: 100, //default user id
    };
    if (order.id) {
      order_input.id = parseInt(order.id);
    }
    if (order.delivery === "for_here") {
      if (!order.table_id && tables.length > 0) {
        order.table_id = tables
          ? parseInt(ev.currentTarget.dataset.table_id)
          : 0;
        order_input.table_id = tables
          ? parseInt(ev.currentTarget.dataset.table_id)
          : 0;
      } else if (order.table_id) {
        order_input.table_id = order.table_id;
      } else {
        order_input.table_id = null;
      }
    } else if (order.delivery === "delivery") {
      order_input.delivery_id = order.delivery_id
        ? parseInt(order.delivery_id)
        : 0;
      order_input.shipping_cost = order.shipping_cost
        ? parseFloat(order.shipping_cost)
        : 0;
    }

    if (buyer) {
      order_input.buyer = {
        id: parseInt(buyer.id),
        name: buyer.name,
        lastname: buyer.lastname,
        phone: buyer.phone,
        email: buyer.email,
      };
    }
    const { data: new_order } = await makeOrderMutation({
      variables: {
        order: order_input,
        setAsReady: settings && settings.restaurant_copy === true,
        // printTicket: true,
      },
    });
    if (new_order) {
      if (settings.printer) {
        if (settings.restaurant_copy === true) {
          printer.print({ ...new_order.makeOrder }, "restaurant", false);
        }
        if (
          settings.print_upfront === true &&
          order.delivery_type !== "for_here"
        ) {
          printer.print({ ...new_order.makeOrder }, "client");
        }
      }

      ///remove local order from localstorage
      localStorage.removeItem("activeOrder");
      history.push(`/order/${new_order.makeOrder.id}`);
    }
  };

  const removeProductHandler = (ev) => {
    const orderState = { ...order };
    const product_key = +ev.currentTarget.dataset.product_key;
    //recalculate totals
    const price = orderState.products[product_key].price;
    orderState.total = orderState.total - price;
    ///remove product
    orderState.products.splice(product_key, 1);
    ///update state
    setOrder({ ...orderState });
    //show resume if Mov
    if (modals.productactions) openModal("resume_mov");
  };

  const changePricePromt = (ev) => {
    const orderState = { ...order };
    const product_key = +ev.currentTarget.dataset.product_key;
    const set_product = orderState.products[product_key];
    set_product.price_mod = set_product.price;
    set_product.product_key = product_key;
    set_product.add_product = false;
    setProduct({ ...set_product });
    setModals({ prices: true });
  };

  const chPriceInput = (ev) => {
    const price_mod = ev.target.value;
    const set_product = { ...product };
    if (isNaN(price_mod)) {
      const price_calc = this.change_price_calc(price_mod);
      set_product.price_calc = price_calc;
    }
    set_product.price_mod = price_mod;
    setProduct({ ...set_product });
  };
  const changePriceHandler = () => {
    const orderState = { ...order };
    const set_product = { ...product };

    const product_key = product.product_key;
    orderState.total = orderState.total - product.price;
    if (product.price_calc) {
      set_product.price = product.price_calc;
    } else {
      set_product.price = +product.price_mod;
    }
    orderState.total = orderState.total + set_product.price;
    orderState.products[product_key] = set_product;
    setProduct(null);
    setOrder({ ...orderState });
    setModals({ prices: false });
  };

  const addCommentPromt = (ev) => {
    const orderState = { ...order };
    const type = ev.currentTarget.dataset.type;
    if (type === "product") {
      const product_key = +ev.currentTarget.dataset.product_key;
      const set_product = orderState.products[product_key];
      set_product.product_key = product_key;
      set_product.add_product = false;
      set_product.comments = set_product.comments || "";
      setProduct({ ...set_product });
      setModals({ comments: true });
      setComment("product");
    } else {
      ///is order comments
      setComment("order");
      setModals({ comments: true });
    }
  };

  const chCommentInput = (ev) => {
    const set_comment = ev.target.value;
    if (comments === "order") {
      const orderState = { ...order };
      orderState.comments = set_comment;
      setOrder({ ...orderState });
    } else {
      const set_product = { ...product };
      set_product.comments = set_comment;
      setProduct({ ...set_product });
    }
  };

  const addComment = () => {
    if (comments !== "order") {
      const orderState = { ...order };
      const set_product = { ...product };
      const product_key = set_product.product_key;
      orderState.products[product_key].comments = set_product.comments;
      setProduct(false);
      setOrder({ ...orderState });
    }
    setModals({ comments: false });
  };

  const phoneInputHandler = (ev) => {
    const phone = ev.currentTarget.value;
    const orderState = { ...order };
    orderState.user.phone = phone;
    setOrder({ ...orderState });
  };

  const orderResumeMovToggle = () => {
    const set_modals = { ...modals };
    set_modals.resume_mov = set_modals.resume_mov ? false : true;
    setModals({ ...set_modals });
  };

  const promptMakeOrderMov = (ev) => {
    const type = ev.currentTarget.dataset.type;
    const orderState = { ...order };
    orderState.delivery = type;
    orderState.make_order = true;
    setOrder({ ...orderState });
  };

  const searchUserPromt = (input) => {
    clearTimeout(timer);
    const text = input.target.value;
    const current_buyer = { ...buyer };
    current_buyer.phone = text;
    setBuyer(current_buyer);
    timer = setTimeout(() => {
      getClients({
        variables: {
          search: text,
          limit: 5,
        },
      });
    }, 500);
  };

  const selectClient = (ev) => {
    let client_key = ev.currentTarget.dataset.client_key;
    if (!client_key) {
      client_key = 0;
    } else {
      const selBuyer = clients.getClients[client_key];
      setBuyer({ ...selBuyer });
    }
    modalHandler("adduserdata");
  };

  const selectClientAddress = (ev) => {
    const address_id = ev.currentTarget.dataset.address_id;
    const selected_addres = clientAddress.getAddresses.find(
      (item) => item.id === address_id
    );
    const set_order = { ...order };
    set_order.delivery_id = address_id;
    set_order.shipping_cost = selected_addres.shipping_cost;
    if (set_order.shipping_cost > 0) {
      set_order.total += set_order.shipping_cost;
    }
    set_order.make_order = true;
    setOrder({ ...set_order });
    modalHandler(null);
  };

  return (
    <main>
      {isMobile ? <NavMob text="Órdenes Activas" link="/" /> : null}

      <div className="container-fluid py-md-3">
        <div className="row ">
          <div className="col-md-8 col-xl-9 p-0 px-sm-2 products_pos">
            <hr />
            {menu_all ? (
              <Products
                menu_all={menu_all}
                addFunction={selectProduct}
                tiggerAdded={tiggerAdded}
              />
            ) : null}
          </div>
          <div className="col-sm-4 col-xl-3 d-none d-md-block  bg-white pos_side">
            <SideUI
              order={order}
              selectIng={selectIng}
              removeProduct={removeProductHandler}
              changePrice={changePricePromt}
              addComment={addCommentPromt}
              handleDelivery={handleDelivery}
              promptMakeOrder={promptMakeOrder}
            />
          </div>
          <ModalOrder
            show={modals.resume_mov}
            order={order}
            handleDelivery={handleDelivery}
            makeOrder={promptMakeOrderMov}
            back={orderResumeMovToggle}
            openOptions={openProductsActions}
          />
        </div>
      </div>
      <BottomCta
        disabled={order.products && order.products.length > 0 ? false : true}
        show={!modals.resume_mov}
        promptOrder={orderResumeMovToggle}
        total={order.total}
      />
      {modals.ingredients ? (
        <ModalIng
          open={modals.ingredients}
          product={product}
          click={removeIngredientHandler}
          toggle={() => modalHandler("ingredients")}
          handleQty={handleQty}
          handleSum={handleSum}
          handleLess={handleLess}
          addProduct={editProductOrder}
        />
      ) : null}
      {modals.options ? (
        <ModalOptions
          open={modals.options}
          product={product}
          click={OptionsHandler}
          next={addProductOrder}
          toggle={() => modalHandler("options")}
        />
      ) : null}
      {modals.tables ? (
        <ModalTables
          open={modals.tables}
          tables={tables}
          // click={TablesHandler}
          next={addProductOrder}
          toggle={() => modalHandler("tables")}
          makeOrder={makeOrder}
        />
      ) : null}
      {modals.sizes ? (
        <ModalSizes
          title={product.title}
          open={modals.sizes}
          sizes={product.sizes}
          click={SizesHandler}
          toggle={() => modalHandler("sizes")}
        />
      ) : null}
      {modals.prices ? (
        <ModalChangePrice
          open={modals.prices}
          price={product.price_mod}
          price_calc={product.price_calc}
          inputFun={chPriceInput}
          click={changePriceHandler}
          toggle={() => modalHandler("prices")}
        />
      ) : null}
      {modals.comments ? (
        <ModalComments
          open={modals.comments}
          comments={comments === "order" ? order.comments : product.comments}
          inputFun={chCommentInput}
          click={addComment}
          toggle={() => modalHandler("comments")}
        />
      ) : null}
      {modals.adduser ? (
        <AddUserModal
          open={modals.adduser}
          inputFun={phoneInputHandler}
          toggle={() => modalHandler("adduser")}
          keyupFun={searchUserPromt}
          selectClient={selectClient}
          users={clients ? clients.getClients : []}
        />
      ) : null}

      {modals.adduserdata ? (
        <AddUserDataModal
          open={modals.adduserdata}
          modalHandler={modalHandler}
          buyer={buyer}
          inputFun={phoneInputHandler}
          nextFun={saveUser}
          handleType={handleBuyerType}
          toggle={() => modalHandler("adduserdata")}
        />
      ) : null}

      {modals.adduseraddress ? (
        <AddAddressModal
          open={modals.adduseraddress}
          modalHandler={modalHandler}
          inputFun={phoneInputHandler}
          loadingAddress={loadingAddress}
          clientAddress={clientAddress ? clientAddress.getAddresses : false}
          selectClientAddress={selectClientAddress}
          toggle={() => modalHandler("adduseraddress")}
          user={{}}
        />
      ) : null}
      {modals.addnewuseraddress ? (
        <AddNewAddressModal
          open={modals.addnewuseraddress}
          address={buyer.address || {}}
          handleNewAddress={handleNewAddress}
          hadleAddressValue={hadleAddressValue}
          saveUserAddress={
            settings.google_maps ? saveUserAddress : deliveryPricePromt
          }
          loading={saving_address}
          inputFun={phoneInputHandler}
          toggle={() => modalHandler("addnewuseraddress")}
          country={settings.country || "mx"}
          user={{}}
          google_maps={settings.google_maps}
        />
      ) : null}
      {modals.shippingpricemodal && (
        <ShippingPriceModal
          open={modals.shippingpricemodal}
          saveUserAddress={saveUserAddress}
          address={buyer.address || {}}
          hadleAddressValue={hadleAddressValue}
          toggle={() => modalHandler("shippingpricemodal")}
        />
      )}
      {modals.productactions ? (
        <ProductActions
          open={true}
          toggle={() => openModal("resume_mov")}
          product_key={modals.productactions}
          selectIng={selectIng}
          removeProduct={removeProductHandler}
          changePrice={changePricePromt}
          addComment={addCommentPromt}
        />
      ) : null}
    </main>
  );
};

export default PointOfSale;
