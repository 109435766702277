import { DateTime } from "luxon";
import EscPosEncoder from "esc-pos-encoder";
import { loadImage } from "canvas";

import commands from "./commands";
import { User } from "../../models/user_model";
import { Branch } from "../../models/partner_model";
import { OrderType } from "../../models/order_model";
import { Settings } from "../../models/settings_model";
import num_to_text from "../../middleware/num_text";

const encoder = new EscPosEncoder();

export type PrintType = "client" | "restaurant";

export async function getTicket(
  order: OrderType,
  settings: Settings,
  type: PrintType,
  re_print: boolean = false
) {
  console.log("getTicket.js:12 | type", type);
  const user: User = JSON.parse(localStorage.getItem("user") || "{}");
  const branch: Branch = JSON.parse(localStorage.getItem("branch") || "{}");
  const { partner } = user;
  const restaurant_name = partner.company_name
    .replace(/[.*+?^${}()|[\]\\]/g, " ")
    .replace("%26", "&");
  let subtotal_text,
    tax_text,
    total_text,
    delivery_text,
    income_text,
    change_text;
  const restaurant_page = partner.url_name;
  const fiscal_data: string | boolean =
    settings.printer_fiscal && partner.fiscal_name
      ? partner.fiscal_name
      : false;
  let user_name = order.user
    ? `${order.user.name || ""} ${order.user.lastname || ""}`
    : `${user.name || ""} ${user.lastname || ""}`;
  const is_payed = order.payed || false;
  if (user_name.length >= 2) {
    user_name = commands.Line + "Atendido por " + user_name;
  } else {
    user_name = "";
  }

  const active_branch_info = branch || false;
  // const active_zone = settings.active_zone;
  const str = "" + order.id;
  const pad = "0000";
  const order_id_txt = "1" + pad.substring(0, pad.length - str.length) + str;
  const turn_txt = "#" + str.slice(-2); //gets last character;
  const total = order.total;
  const total_txt = num_to_text(total);
  const iva = total - total / 1.16;
  const subtotal = total - iva;
  let has_products: boolean = false;
  let dateTime = order.creation_date
    ? DateTime.fromMillis(Number(order.creation_date)).toLocaleString(
        DateTime.DATETIME_MED
      )
    : DateTime.local();
  let delivery_info: string | boolean = false;
  let table_info: string | boolean = false;
  let delivery: string = "En Comedor";
  const products_zones: boolean = settings.printer_type === "network";

  switch (order.delivery_type) {
    case "for_here":
      delivery = "En Comedor";
      table_info = true;
      break;
    case "pickup":
      delivery = "Para Llevar";
      break;
    case "delivery":
      delivery = "A Domicilio";
      delivery_info = true;
      break;
    case "other_apps":
      delivery = "Plataformas";
      break;
    default:
      delivery = "En Comedor";
  }

  if (table_info && order.table.id) {
    table_info =
      commands.Line +
      `Mesa # ${order.table.name || order.table.id}` +
      commands.Line;
  } else {
    table_info = "";
  }

  const print_text = encoder.initialize().codepage("cp850");
  if (type === "client") {
    if (settings.ticket_logo) {
      const logo = await loadImage(settings.ticket_logo, {
        crossOrigin: "Anonymous",
      });
      print_text.align("center").image(logo, 160, 160).newline();
    }
    print_text
      .newline()
      .bold()
      .text(restaurant_name)
      .bold()
      .newline()
      .align("left");
  }

  //pring fiscal data if any
  if (type === "client" && fiscal_data && is_payed && partner.rfc) {
    print_text.line(fiscal_data).line(partner.rfc);
  }
  ////add address
  if (type === "client" && active_branch_info.address) {
    print_text.line(active_branch_info.address);
    print_text.line(active_branch_info.phone);
  }

  if (order_id_txt) {
    if (type === "client") {
      print_text.align("right").line(`Orden #${order_id_txt}`);
    } else {
      print_text
        .align("center")
        .text(commands.DoubleOn)
        .line("#" + order_id_txt)
        .text(commands.DoubleOff)
        .line("");
    }
  }
  print_text.line(`Fecha  ${dateTime}`);

  const turn_text =
    commands.enter +
    commands.CenterOn +
    "Turno:" +
    commands.Line +
    commands.DoubleOn +
    turn_txt +
    commands.DoubleOff +
    commands.Line +
    commands.LeftOn;

  print_text.line(turn_text);
  print_text
    .line(`${user_name} ${table_info}`)
    .newline()
    .newline()
    .align("left");

  if (order.client_comments && order.client_comments !== true) {
    if (type === "client") {
      print_text.bold().line("Comentarios").bold();
      print_text.line(order.client_comments);
    } else {
      print_text.line(commands.h3On + "Comentarios " + commands.h3Off);
      print_text
        .line(commands.h3On + order.client_comments + commands.h3Off)
        .newline()
        .newline();
    }
  }
  if (order.user_comments && order.user_comments !== true) {
    if (type === "client") {
      print_text.bold().line("Comentarios del Ciente").bold();
      print_text.line(order.user_comments);
    } else {
      print_text.line(
        commands.h3On + "Comentarios del Cliente" + commands.h3Off
      );
      print_text.line(commands.h3On + order.user_comments + commands.h3Off);
    }
  }
  const createdProducts = order.createdProducts ? order.createdProducts : [];

  order.products.forEach((product) => {
    let print_help = false;
    let size_text = "";
    let comment_text = "";
    let ing_text = "";
    let help_text = "";
    if (product.ingredients_txt) {
      print_help = true;
      ing_text = product.ingredients_txt
        .split(" | ")
        .filter((item) => item !== "" && item !== " ")
        .map((item) => "   -" + item + commands.Line)
        .join("");
    }
    if (product.size) {
      const size_data = product.product.sizes.find(
        (s) => s.id === product.size
      );
      print_help = true;
      size_text = size_data ? size_data.name + ", " : "";
    }
    if (product.comments) {
      comment_text = commands.Line + "   **" + product.comments;
    }
    if (print_help) {
      help_text = commands.Line + "   " + size_text;
      help_text += ing_text;
    }
    const product_print_price = product.price;
    if (type === "restaurant") {
      if (!createdProducts.includes(+product.id) && !re_print) {
        return;
      }
      print_text.line(
        commands.h3On +
          `${product.product.title}${
            product.qty > 1 ? ` x${product.qty}` : ""
          } ${comment_text} ${help_text}` +
          commands.h3Off
      );
    } else {
      print_text.line(
        `${product.product.title} ${
          product.qty > 1 ? ` x${product.qty}` : ""
        } ${comment_text} ${help_text}`
      );
    }
    if (type === "client" || settings.price_ticket) {
      print_text.line(`$${product_print_price}`);
    }
    has_products = true;
  });
  if (delivery_info) {
    let delivery_info_text =
      commands.enter + "Costo Envio: $" + order.delivery.toPrecision(5);
    print_text.line(delivery_info_text);
    subtotal_text = commands.Line + "Subtotal: $" + subtotal.toPrecision(5);
  } else {
    subtotal_text = commands.enter + "Subtotal: $" + subtotal.toPrecision(5);
  }

  tax_text = commands.Line + "Iva: $" + iva.toPrecision(5);
  if (settings.printer_iva === false) {
    tax_text = "";
    subtotal_text = "";
  }
  total_text =
    commands.Line +
    commands.RightOn +
    commands.h3On +
    "Total: $" +
    order.total +
    commands.Line +
    commands.h3Off +
    total_txt;
  delivery_text =
    commands.enter +
    commands.CenterOn +
    commands.DoubleOn +
    delivery +
    commands.DoubleOff +
    commands.Line +
    commands.LeftOn;
  if (type === "client" || settings.price_ticket) {
    print_text.line(subtotal_text + tax_text + total_text);
    if (order.cash_income && order.payment_type === "cash") {
      const change = order.cash_income - order.total;
      income_text =
        commands.Line +
        "Efectivo Recibido: $" +
        order.cash_income.toPrecision(5);
      change_text = commands.Line + "Su Cambio: $" + change.toPrecision(5);
      print_text.line(income_text + change_text);
    } else if (order.payment_type === "terminal") {
      income_text = commands.Line + "Cobrado con Terminal";
      print_text.line(income_text);
    } else if (order.payment_type === "online") {
      income_text = commands.Line + "Pagado online desde FooApp";
      print_text.line(income_text);
    } else if (order.payment_type === "card_pos") {
      income_text = commands.Line + "Pagado online";
      print_text.line(income_text);
    }
  }

  print_text.line(delivery_text);

  if (delivery_info) {
    /// add delivery info to ticket
    let delivery_info_text = "";
    if (order.address && order.address !== true) {
      delivery_info_text =
        commands.enter +
        commands.BoldOn +
        "Dirección de entrega: " +
        commands.BoldOff +
        commands.Line +
        order.address.address +
        commands.Line +
        `${order.address.city ? `${order.address.city},` : ""}` +
        `${order.address.zip ? `${order.address.zip},` : ""}` +
        commands.Line +
        commands.BoldOn +
        "Comentarios:" +
        commands.BoldOff +
        commands.Line +
        order.address.comments;
    }
    delivery_info_text +=
      commands.Line +
      commands.BoldOn +
      "Cliente: " +
      commands.BoldOff +
      order.client.name +
      " " +
      order.client.lastname +
      commands.Line +
      commands.BoldOn +
      "Tel: " +
      commands.BoldOff +
      order.client.phone;
    print_text.line(delivery_info_text);
  }

  if (restaurant_page && type === "client") {
    print_text
      .newline()
      .line("Pide en commands.Linea en")
      .bold()
      .line(`${restaurant_page}.fooapp.mx`)
      .bold();
  }
  if (type === "client") {
    if (settings.ticket_txt) {
      print_text.newline().line(settings.ticket_txt);
    } else {
      print_text.newline().line("Gracias por su compra").line("Vuelva Pronto");
    }
  }

  if (!is_payed && type === "client") {
    print_text
      .newline()
      .line("*** Este ticket no es un ")
      .line("comprobante de pago ***");
  }
  if (settings.printer_iva === false && type === "client") {
    print_text.line("*Precios no incluyen IVA*");
  }
  if (type === "client") {
    print_text.newline().line("** fooapp.mx **");
  } else {
    print_text.newline().line("Copia del Negocio");
  }
  print_text.newline().newline().newline().newline().newline();

  if (products_zones) {
    ////TODO validate product zones on tickets
  }
  const open_drawer = settings.open_drawer ? commands.drawer : "";
  print_text.line(open_drawer);
  return {
    print_text: print_text.cut("partial").encode(),
    has_products,
  };
}
