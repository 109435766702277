import React, { useState, useEffect } from "react";

import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";

import useIdle from "../../../hooks/useIdle";
import { UPDATEORDER, SAVETOKEN } from "../order/queries";

import {
  GETTABLES,
  GETORDERS,
  GETORDERSPRODUCTS,
  GETPRODUCTS,
} from "./queries";
import useSettings from "../../../hooks/settings";
import Head from "./head";
import OrderList from "./list";
import OrderListMob from "./listMob";
import NewOrderBtn from "./new_btn";
import TableView from "./tables";
import ProductsView from "./by_products";
import { OrderType, OrderViewType, Table } from "../../../models/order_model";
import { useHistory } from "react-router";
import { activeBranchVar } from "../../../middleware/cache";

const Orders: React.FC = () => {
  const history = useHistory();
  const isIdle = useIdle({
    timeToIdle: 30 * 60 * 1000,
    activityEvents: ["click", "mousemove", "mousewheel", "touchmove"],
  });
  const activeBranch = useReactiveVar(activeBranchVar);
  const polling_time = 5000;
  const [settings, saveSettings] = useSettings();
  const [updateOrderMutation] = useMutation(UPDATEORDER);
  const [saveNotificationToken] = useMutation(SAVETOKEN);
  const [hasOrders, sethasOrders] = useState(false);
  const [rendering, setRendering] = useState(true);
  const [tables, setTables] = useState<Table[]>([]);
  const [localOrders, setLocalOrders] = useState<{
    past: OrderType[];
    active: OrderType[];
  }>({ past: [], active: [] });

  const [
    getOrdersQuery,
    {
      data: orders,
      stopPolling: stopPollingOrders,
      startPolling: startPollingOrders,
    },
  ] = useLazyQuery(GETORDERS, {
    fetchPolicy: "no-cache",
    pollInterval: polling_time,
  });
  const [loadPastOrdersQuery, { data: past_orders }] = useLazyQuery(GETORDERS, {
    fetchPolicy: "no-cache",
  });
  const [
    getOrdersProductsQuery,
    {
      data: orders_products,
      stopPolling: stopPollingOrdersProducts,
      startPolling: startPollingOrdersProducts,
    },
  ] = useLazyQuery(GETORDERSPRODUCTS, {
    fetchPolicy: "no-cache",
  });
  const [
    getTables,
    {
      data: tablesDb,
      stopPolling: stopPollingTables,
      startPolling: startPollingTables,
    },
  ] = useLazyQuery(GETTABLES, {
    fetchPolicy: "no-cache",
  });
  const [getProducts, { data: productsDb }] = useLazyQuery(GETPRODUCTS, {
    fetchPolicy: "no-cache",
  });

  const tables_local = JSON.parse(localStorage.getItem("tables") || "[]");
  const menu_all_local = JSON.parse(localStorage.getItem("menu_all") || "{}");
  const local_orders = JSON.parse(localStorage.getItem("all_orders") || "[]");

  useEffect(() => {
    console.log("index.js:78 | isIdle", isIdle);
    if (isIdle) {
      togglePolling(false);
    } else {
      togglePolling(true);
    }
    return () => {
      togglePolling(false);
    };
    // eslint-disable-next-line
  }, [
    isIdle,
    startPollingOrdersProducts,
    startPollingTables,
    startPollingOrders,
  ]);

  useEffect(() => {
    if (settings.active_view === "products") {
      ///fetch orders with products
      getOrdersProductsQuery({
        variables: { branch_id: +activeBranch },
      });
    } else if (settings.active_view === "order") {
      loadPastOrders();
      getOrdersQuery({
        variables: {
          limit: 50,
          active: true,
          branch_id: +activeBranch,
        },
      });
    }
    ///save firebase token on DB
    const notifications_token = localStorage.getItem("ntt");
    if (notifications_token) {
      saveNotificationToken({ variables: { token: notifications_token } });
    }
    loadMenu();
    if (settings.active_view === "table") {
      getTables({
        variables: {
          branch: +activeBranch,
        },
      });
    }
    if (!tables_local || tables_local.length === 0) {
      setTables(tables_local);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBranch, settings]);

  useEffect(() => {
    if (settings.active_view !== "products" && orders) {
      const all_orders = [...orders.getOrders];
      if (past_orders) all_orders.push(...past_orders.getOrders);
      console.log("index.js:82 | all_orders", all_orders);
      localStorage.setItem("all_orders", JSON.stringify(all_orders));
      renderOrders(all_orders);
    } else if (settings.active_view === "products" && orders_products) {
      localStorage.setItem(
        "all_orders",
        JSON.stringify(orders_products.getOrders)
      );
      renderOrders(orders_products.getOrders);
    } else {
      getOrdersLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, orders_products, past_orders]);

  useEffect(() => {
    if (tablesDb) {
      localStorage.setItem("tables", JSON.stringify(tablesDb.getTables));
      setTables(tablesDb.getTables);
    }
  }, [tablesDb]);

  useEffect(() => {
    if (productsDb) {
      const categories = [...productsDb.getCategories].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      const menu_obj = {
        products: [...productsDb.getProducts],
        categories,
      };
      localStorage.setItem("menu_all", JSON.stringify(menu_obj));
    }
  }, [productsDb]);

  const togglePolling = (set: boolean) => {
    if (
      (settings.active_view === "products" && !startPollingOrdersProducts) ||
      (settings.active_view === "table" && !startPollingTables) ||
      (settings.active_view === "order" && !startPollingOrders)
    ) {
      return;
    }
    if (
      !startPollingOrders ||
      !startPollingTables ||
      !startPollingOrdersProducts ||
      !stopPollingOrders ||
      !stopPollingOrdersProducts ||
      !stopPollingTables
    ) {
      return;
    }
    if (set) {
      switch (settings.active_view) {
        case "products":
          startPollingOrdersProducts(polling_time);
          break;
        case "table":
          startPollingTables(polling_time);
          break;
        case "order":
        default:
          startPollingOrders(polling_time);
          break;
      }
    } else {
      switch (settings.active_view) {
        case "products":
          stopPollingOrdersProducts();
          break;
        case "table":
          stopPollingTables();
          break;
        case "order":
        default:
          stopPollingOrders();
          break;
      }
    }
  };

  const loadMenu = () => {
    if (!menu_all_local || Object.keys(menu_all_local).length === 0) {
      console.log("NO MENU");
      getProducts();
    }
  };

  const getOrdersLocal = () => {
    if (local_orders) {
      renderOrders(local_orders);
    }
  };

  const loadPastOrders = () => {
    loadPastOrdersQuery({
      variables: {
        limit: 20,
        active: false,
        branch_id: +activeBranch,
      },
    });
  };

  const renderOrders = (all_orders: any) => {
    const active: OrderType[] = [];
    const past: OrderType[] = [];
    let activeStatus = [
      "new",
      "ongoing",
      "in_progress",
      "idle",
      "placed",
      "ready",
    ];
    Object.keys(all_orders).map((key) => {
      const order = all_orders[key];
      if (settings.active_view === "products") {
        activeStatus = ["new", "ongoing", "in_progress", "placed"];
      }
      if (
        activeStatus.indexOf(order.status) >= 0 ||
        (settings.active_view !== "products" &&
          order.status === "fulfilled" &&
          !order.payed)
      ) {
        active.push(order);
      } else {
        past.push(order);
      }
      return true;
    });
    const set_orders = { active: active.reverse(), past };
    const hasOrders = set_orders.active.length ? true : false;
    setLocalOrders(set_orders);
    sethasOrders(hasOrders);
    setRendering(false);
  };

  const goToOrder = (ev: any) => {
    const order_id = ev.currentTarget.dataset.order_id;
    history.push(`/order/${order_id}`);
  };

  const updateOrder = async (ev: any) => {
    const order_id = ev.currentTarget.dataset.order_id;
    console.log("index.js:122 | order_id", order_id);
    try {
      const orders_temp = { ...localOrders };
      const order_index = orders_temp.active.findIndex(
        (item: OrderType) => item.id === order_id
      );
      const updateOrderInput = {
        order_id: parseInt(order_id),
        status: "fulfilled",
      };
      orders_temp.active.splice(order_index, 1);
      updateOrderMutation({ variables: { order: updateOrderInput } });
      setLocalOrders({ ...orders_temp });
      const hasOrders = orders_temp.active.length ? true : false;
      sethasOrders(hasOrders);
    } catch (error) {
      console.log("index.js:90 | error", error);
    }
  };

  const selectView = (view: OrderViewType) => {
    const set_settings = { ...settings };
    set_settings.active_view = view as string;
    saveSettings("active_view", view);
  };

  const renderView = () => {
    switch (settings.active_view) {
      case "table":
        return renderTableView();
      case "products":
        return renderProductsView();
      default:
        return renderOrdersList();
    }
  };

  const renderTableView = () => {
    return <TableView tables={tables} />;
  };

  const renderProductsView = () => {
    return (
      <ProductsView
        orders={localOrders.active}
        hasOrders={hasOrders}
        updateOrder={updateOrder}
        clickFun={goToOrder}
      />
    );
  };

  const renderOrdersList = () => {
    return (
      <React.Fragment>
        <OrderListMob orders={localOrders} hasOrders={hasOrders} />
        <OrderList
          orders={localOrders}
          hasOrders={hasOrders}
          clickFun={goToOrder}
          loadPastOrders={loadPastOrders}
        />
      </React.Fragment>
    );
  };

  return (
    <main>
      <Head
        selectView={selectView}
        active_view={
          settings?.active_view
            ? (settings.active_view as unknown as OrderViewType)
            : OrderViewType.order
        }
      />
      {rendering ? null : renderView()}
      <NewOrderBtn />
    </main>
  );
};

export default Orders;
