import { gql } from "@apollo/client";

export const GETORDER = gql`
  query getOrder($order_id: Int!) {
    getOrder(id: $order_id) {
      id
      type
      status
      status_color
      subtotal
      total
      address {
        id
        address
        comments
        location
      }
      creation
      creation_date
      modification
      user_comments
      client_comments
      resume_txt
      delivery_type
      delivery
      payment_type
      card_type
      cash_income
      payed
      branch {
        id
        name
      }
      client {
        id
        name
        lastname
        phone
      }
      user {
        id
        name
        lastname
      }
      table {
        id
        name
      }
      products {
        id
        status
        price
        ingredients_txt
        comments
        qty
        qty_done
        product {
          id
          title
          images {
            url
          }
        }
      }
    }
  }
`;

export const UPDATEORDER = gql`
  mutation updateOrder($order: UpdateOrderInput) {
    updateOrderTable(order: $order) {
      id
    }
  }
`;

export const UPDATETABLE = gql`
  mutation updateOrderTable($order: UpdateOrderInput) {
    updateOrderTable(order: $order) {
      id
    }
  }
`;

export const UPDATEPRODUCT = gql`
  mutation updateOrderProduct($product: UpdateOrderProductInput) {
    updateOrderProduct(product: $product) {
      id
    }
  }
`;

export const PAYORDER = gql`
  mutation payOrder($payment: PaymentInput) {
    payOrder(payment: $payment) {
      id
      payed
      cash_income
      total
      payments {
        id
        amount
        payment_type
      }
    }
  }
`;

export const SAVETOKEN = gql`
  mutation saveToken($token: String!) {
    saveToken(token: $token)
  }
`;
