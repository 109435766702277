import React from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { toDate } from "../../../middleware/dates";

import SalesTable from "./table";
import { sales_colors } from "./colors";

import { SalesType } from "../../../models/sales_model";

type SalesResumeProps = {
  sales_data: SalesType;
};

type DailySales = {
  time: string;
  total: number;
};

const border_colors = ["rgba(142, 168, 195, 0)"];
const options = {
  plugins: {
    scales: false,
    legend: {
      position: "bottom",
      display: false,
    },
  },
};

const bars_options = {
  plugins: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.95,
          categoryPercentage: 1,
          display: true,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
};

const Charts: React.FC<SalesResumeProps> = ({ sales_data }) => {
  const day_times: string[] = [];
  const day_sales: DailySales[] = [];
  for (let index = 0; index < 24; index++) {
    day_times.push(`${index}:00`);
    day_sales.push({ time: `${index}`, total: 0 });
  }

  sales_data.timeline.forEach((sale) => {
    const split = toDate(sale.date_txt, {}, "hour");
    const time = split.split(":")[0];
    const total = sale.total;
    const finded = day_sales.find((s) => s.time === time);
    if (finded) {
      finded.total += total;
    } else {
      day_sales.push({ time, total });
    }
  });

  const timeline_data = {
    labels: day_times,
    datasets: [
      {
        label: "Total de Ventas",
        data: day_sales.map((sale) => sale.total),
        backgroundColor: day_sales.map(() => sales_colors.delivery),
        borderColor: day_sales.map(() => sales_colors.delivery),
        borderWidth: 1,
      },
    ],
  };

  const delivery_data = {
    labels: sales_data.delivery.map((item) => item.type_txt),
    datasets: [
      {
        label: "Forma de Entrega",
        data: sales_data.delivery.map((item) => item.total),
        backgroundColor: sales_data.delivery.map(
          (item) => sales_colors[item.type]
        ),
        borderColor: sales_data.delivery.map(() => border_colors[0]),
        borderWidth: 1,
      },
    ],
  };

  const payment_data = {
    labels: sales_data.payment.map((item) => item.type_txt),
    datasets: [
      {
        label: "Forma de Pago",
        data: sales_data.delivery.map((item) => item.total),
        backgroundColor: sales_data.payment.map(
          (item) => sales_colors[item.type]
        ),
        borderColor: sales_data.payment.map(() => border_colors[0]),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="row mt-3 charts">
      <div className="col-md-4 ">
        <div className="material-shadows chart-item">
          <div className="row justify-content-center">
            <div className="col-md-10 my-3">
              <Doughnut type="" data={delivery_data} options={options} />
            </div>
          </div>
          <SalesTable data={sales_data.delivery} />
        </div>
      </div>
      <div className="col-md-4 ">
        <div className="material-shadows chart-item">
          <div className="row justify-content-center">
            <div className="col-md-10 my-3">
              <Doughnut type="" data={payment_data} options={options} />
            </div>
          </div>
          <SalesTable data={sales_data.payment} />
        </div>
      </div>
      <div className="col-md-4 ">
        <div className="material-shadows chart-item p-3">
          <Bar type="" data={timeline_data} options={bars_options} />
        </div>
      </div>
    </div>
  );
};

export default Charts;
